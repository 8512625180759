import React from 'react';
import {Colors} from '_styles';

const Dot = props => {
	return (
		<div
			style={{
				borderRadius: 8,
				width: 8,
				height: 8,
				backgroundColor: !!props.color ? props.color : Colors.primary,
				marginInline: 10,
			}}
		/>
	);
};

export default Dot;
