import {Empty, Select} from 'antd';
import {Colors, Typography} from '_styles';
import {Icon, Text} from '_atoms';
import '_styles/styles.css';
import {SelectOption} from '_molecules';
export default ({emptyText = 'Sem opções disponíveis', optionFilterProp = 'value', options, ...props}) => {
	return (
		<div style={{width: '100%'}}>
			{props.label && (
				<Text style={{...Typography.regular22, color: Colors.orange, paddingLeft: 18}}>
					{props.label}
					{props.required ? '*' : ''}
				</Text>
			)}
			<div
				style={{
					...props.style,
				}}
				className={'custom-input-wrapper'}>
				<Select
					{...props}
					filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
					// optionFilterProp={optionFilterProp}
					style={{...Typography.regular20, color: Colors.black, width: '100%', textAlign: 'left'}}
					bordered={false}
					suffixIcon={<Icon name={'ant-design:caret-down-outlined'} color={Colors.primary} size={18} />}
					dropdownStyle={{borderRadius: 25}}
					notFoundContent={<Empty description={emptyText} />}>
					{options?.map((v, k) => (
						<SelectOption key={k} value={v.value}>
							{v.label ?? v.value}
						</SelectOption>
					))}
				</Select>
			</div>
		</div>
	);
};
