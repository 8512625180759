import fetch from './api-service';

const uploadLogoService = {};

uploadLogoService.post = async function (values, id) {
	const formData = new FormData();
	formData.append('file', values.file);
	formData.append('id_pessoa_juridica', id);
	const teste = await fetch({
		url: '/v1/rest/upload-logo',
		method: 'post',
		body: formData.file,
		headers: {
			'Content-Type': 'multipart/form-data',
			is_app: true,
		},
	});
};

uploadLogoService.get = async function (id) {
	return fetch({
		url: `/v1/rest/upload-logo/${id}`,
		method: 'get',
	});
};

export default uploadLogoService;
