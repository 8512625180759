import imagem from './404.png';
import {Button, Dot, Icon, Separator, Text} from '_atoms';
import {Colors, Typography} from '_styles';
export default props => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
				minHeight: 700,
			}}>
			<Text style={{...Typography.extraBold30, color: Colors.orange}}>
				Você não possui permissão de acesso para essa tela
			</Text>
			<Separator size={50} />
			<img src={imagem} style={{width: 530, height: 258, alignSelf: 'center'}} />
			<Separator size={50} />
			<Button type={'custom'} onClick={() => window.history.back()}>
				<Text style={{...Typography.extraBold30, color: Colors.orange}}>Voltar</Text>
			</Button>
		</div>
	);
};
