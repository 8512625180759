import {Alert, Col, Form, Row, Checkbox} from 'antd';
import {FormItem} from '_molecules';
import {Button, Loading, Text} from '_atoms';
import {useRef} from 'react';
import {Colors, Typography} from '_styles';

export default ({
	fields,
	onValueChange,
	values,
	confirmLabel = 'Finalizar',
	backOptionLabel = 'Voltar',
	backOption = false,
	backOptionClick,
	error = false,
	showFooter = true,
	confirmOption = true,
	waiting = false,
	childrenExtra = false,
	...props
}) => {
	const renderItem = item => {
		const {type, name, valuePropName, rules, ...props} = item;
		const Input = type;
		if (rules?.message) {
			rules.message = rules.message;
		} else {
			if (rules?.required && !rules?.message) {
				rules.message = 'Por favor preencha o campo ' + props.label;
			}
			if (!!rules?.min) {
				if (!!rules?.minMessage) {
					rules.message = rules?.minMessage;
				} else rules.message = `O ${props.label} está incompleto`;
			}
		}
		return (
			<div>
				{valuePropName === 'checked' ? (
					<Form.Item
						name={name}
						rules={[rules]}
						style={{width: '100%'}}
						valuePropName="checked"
						label={
							<Text style={{...Typography.regular22, color: Colors.orange, paddingLeft: 18}}>
								{props.label}
								{props.required ? '*' : ''}
							</Text>
						}>
						<Checkbox />
					</Form.Item>
				) : (
					<FormItem name={name} rules={[rules]} style={{width: '100%'}} valuePropName={valuePropName}>
						<Input
							{...props}
							required={rules?.required}
							minLength={!!rules?.min ? rules?.min : 1}
							style={{width: '100%'}}
						/>
					</FormItem>
				)}
			</div>
		);
	};
	const localFields = useRef([]);

	return (
		<Form
			{...props}
			fields={Object.keys(values).map((v, k) => ({name: v, value: values[v]}))}
			onFieldsChange={(input, allFields) => {
				localFields.current = allFields;
				input = input[0];
				if (typeof onValueChange === 'function') {
					onValueChange({...values, [input?.name?.[0]]: input.value});
				}
			}}>
			{fields.map(v => {
				if (Array.isArray(v)) {
					return (
						<Row gutter={20} style={{width: '100%', justifyContent: 'center'}}>
							{v.map((item, key) => {
								let isBig = false;
								if (v.length % 2 !== 0) {
									isBig = key === v.length - 1;
								}
								return (
									<Col
										lg={isBig ? 24 : 12}
										md={24}
										sm={24}
										xs-={24}
										style={{width: '100%', padding: 10}}>
										<div style={{width: '100%'}}>{renderItem(item)}</div>
										{key !== v.length - 1 && <div style={{width: 3}} />}
									</Col>
								);
							})}
						</Row>
					);
				} else {
					return (
						<Row gutter={20} style={{width: '100%', justifyContent: 'center'}}>
							<Col lg={24} md={24} sm={24} xs-={24} style={{width: '100%', padding: 10}}>
								{renderItem(v)}
							</Col>
						</Row>
					);
				}
			})}

			{childrenExtra && <div style={{width: '100%'}}>{childrenExtra}</div>}

			{error && <Alert type="error" message={error} banner />}
			{showFooter && (
				<FormItem style={{display: 'flex', justifyContent: 'center', marginTop: 50}}>
					{!!waiting && <Loading />}
					{!waiting && (
						<div style={{display: 'flex', justifyContent: 'center'}}>
							{backOption && (
								<Button
									type="custom"
									onClick={backOptionClick}
									style={{width: 'auto', marginInline: 20}}>
									<Text style={{...Typography.medium22, color: Colors.primary}}>
										{backOptionLabel}
									</Text>
								</Button>
							)}
							{confirmOption && (
								<Button
									// loading={waiting}
									type="primary"
									htmlType="submit"
									style={{width: 'auto', paddingInline: 20}}>
									{confirmLabel}
								</Button>
							)}
						</div>
					)}
				</FormItem>
			)}
		</Form>
	);
};
