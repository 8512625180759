import {Empty, Modal, Select} from 'antd';
import '_styles/styles.css';
import {Colors, Typography} from '_styles';
export default ({title, open, body, ...props}) => {
	return (
		<Modal {...props} open={open} title={title}>
			{body}
		</Modal>
	);
};
