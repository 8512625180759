import {TimePicker} from 'antd';
import {Text} from '_atoms';
import {Colors, Typography} from '_styles';
import './styles.css';
const style = {...Typography.regular20};

export default ({format = 'HH:mm', placeholder, range = false, ...props}) => {
	placeholder = placeholder ? placeholder : range ? ['Início', 'Fim'] : 'Selecione a hora';
	return (
		<div style={{width: '100%'}}>
			{props.label && (
				<Text style={{...Typography.regular22, color: Colors.orange, paddingLeft: 18}}>
					{props.label}
					{props.required ? '*' : ''}
				</Text>
			)}
			<div
				style={{
					...props.style,
				}}
				className={'custom-input-wrapper'}>
				{!range && (
					<TimePicker
						style={{...style, width: '100%', ...props.style}}
						format={format}
						placeholder={placeholder}
						bordered={false}
						showNow={false}
					/>
				)}
				{range && (
					<TimePicker.RangePicker
						style={{...style, width: '100%', ...props.style}}
						format={format}
						placeholder={placeholder}
						bordered={false}
						showNow={false}
						onChange={(dayjs, string) => {
							props.setValue(dayjs);
						}}
					/>
				)}
			</div>
		</div>
	);
};
