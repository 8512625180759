import Logo from './logo.svg';
import {Dot, Icon, Text} from '_atoms';
import {Colors, Typography} from '_styles';
import Separator from '_atoms/separator';
import {RatingStar} from '_molecules';
import {useEffect, useState} from 'react';
import {UtilsService} from '_services';
import FaculdadeService from '_services/faculdadeService';
import {getUsuario} from '_services/usuarioService';
import {ALUNO} from '_services/tipoPessoaService';
import {useNavigate} from 'react-router-dom';
import {AUTH_TOKEN} from '../../../redux/constants/Auth';
import {DISCIPLINA} from '../../../redux/constants/User';
import {Col, Grid, Row} from "antd";
import utils from "../../../utils";

export default ({...props}) => {
	const user = getUsuario();
	const navigate = useNavigate();
	const disciplina = props.disciplina;
	const [faculdade, setFaculdade] = useState(null);

	useEffect(() => {
		(async () => {
			const resp = await FaculdadeService.get(disciplina?.id_faculdade);
			setFaculdade(resp?.data);
		})();
	}, []);

	const screens = utils.getBreakPoint(Grid.useBreakpoint());
	const isMobile = !screens.includes("lg");

	return (
		<a
			onClick={() => {
				if (!!props?.faculdade && faculdade?.id == props?.faculdade) {
					localStorage.setItem(DISCIPLINA, disciplina.id);
					navigate('/disciplina');
				}
				if (!props?.aluno && !props?.faculdade) {
					localStorage.setItem(DISCIPLINA, disciplina.id);
					navigate('/disciplina');
				}
				if (!!props?.aluno) {
					localStorage.setItem(DISCIPLINA, disciplina.id);
					if (localStorage?.getItem(AUTH_TOKEN) && user?.id_tipo_pessoa == ALUNO) {
						navigate('/matricula-disciplina');
					} else {
						navigate('/disciplina');
					}
				}
			}}>
			<div
				type={'custom'}
				style={{
					flex: 1,
					display: 'flex',
					flexDirection: 'row',
					boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
					borderRadius: 20,
					marginBottom: 30,
					//maxHeight: 240,
					backgroundColor: (disciplina.ativo ? 'transparent' : '#e8e2e2'),
					//width: 'auto',
				}}>

				<Row>
					<Col md={24} sm={24} xs={24} lg={5}>
						<div style={{
							borderRadius: 20,
							height: "100%",
							width: "100%",
							backgroundColor: "#f6f1f1",
							padding: (!!disciplina?.logo ? 8 : 8),
						}}>
							<img src={!!disciplina?.logo ? `data:image/jpeg;base64,${disciplina?.logo}` : Logo}
							     width={"100%"}
							     height={"100%"}
							     style={{objectFit: "scale-down", borderRadius: 18}}
							     alt={"no img"}
							/>
						</div>
					</Col>
					<Col md={24} lg={19}>
						<div style={{padding: 15, paddingRight: 25}}>
							<Row gutter={[15, 15]}>
								<Col md={24} lg={18}>
									<div style={{display: "flex"}}>

										<div style={{
											borderColor: "#000",
											borderRadius: 14,
											borderWidth: 1,
											borderStyle: "solid",
											padding: 2,
											marginRight: 10,
										}}>
											<img
												src={!!faculdade?.logo ? `data:image/jpeg;base64,${faculdade?.logo}` : Logo}
												style={{
													width: 70,
													height: 61,
													borderRadius: 10,
												}}
												alt={"no img"}
											/>
										</div>
										<Row
											gutter={0}
										>
											<Col style={{display: "flex", alignItems: "center"}}>
												<Text style={!isMobile ? {
													...Typography.bold25,
													color: Colors.primary
												} : {
													color: Colors.primary,
													fontSize: 15,
													fontWeight: 700
												}}>{disciplina?.nome} </Text>
											</Col>
											<Col style={{display: "flex", alignItems: "center"}}>
												{!!disciplina?.carga_horaria && (
													<>
														<Dot/>
														<Text style={!isMobile ? {
															...Typography.bold25,
															color: Colors.primary
														} : {color: Colors.primary, fontSize: 15, fontWeight: 700}}>
															{disciplina?.carga_horaria}h
														</Text>
													</>
												)}
											</Col>
											<Col style={{display: "flex", alignItems: "center"}}>
												{!!disciplina?.pessoa_juridica && (
													<>
														<Dot/>
														<Text style={!isMobile ? {
															...Typography.bold25,
															color: Colors.primary
														} : {color: Colors.primary, fontSize: 15, fontWeight: 600}}>
															{disciplina?.pessoa_juridica?.nome_fantasia ||
																disciplina?.pessoa_juridica?.razao_social}
														</Text>
													</>
												)}
											</Col>
										</Row>
									</div>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											minWidth: '60%',
										}}
									>

										<Separator size={9}/>
										<div
											style={{
												minHeight: '45%',
												maxHeight: '45%',
											}}>
											{disciplina?.ementa.length < 180 ? (
												<Text

													style={!isMobile ? {
														...Typography.regular20,
														color: Colors.orange,
														textAlign: 'justify'
													} : {
														color: Colors.orange,
														fontSize: 15,
														fontWeight: 600,
														textAlign: 'justify'
													}}

												>{disciplina?.ementa}</Text>
											) : (
												<Text style={!isMobile ? {
													...Typography.regular20,
													color: Colors.orange,
													textAlign: 'justify'
												} : {
													color: Colors.orange,
													fontSize: 15,
													fontWeight: 600,
													textAlign: 'justify'
												}}
												>
													{disciplina?.ementa.substring(0, 180)}...
												</Text>
											)}
										</div>
										<Separator size={9}/>
									</div>
								</Col>
								<Col md={24} sm={24} xs={24} lg={6}>
									<Row justify={isMobile ? "space-between" : "center"} gutter={[8, 30]}
									     style={{width: "100%"}}>
										<Col sm={8} xs={8} md={8} lg={24}>
											{(disciplina?.ativo) ? (<RatingStar
												disabled={true}
												rating={parseInt(disciplina?.nota)}
												quantidadeAvaliacoes={disciplina?.quantidade_avaliacao}
											/>) : (
												<div style={{width: "100%", textAlign: "center"}}>
													<span style={{fontSize: 20, fontWeight: 650, color: "red"}}>
														Disciplina Inativa
													</span>
												</div>
											)
											}
										</Col>
										<Col sm={6} xs={6} md={6} lg={24}>
											<div style={{textAlign: "center"}}>
												<div>
													<Text
														style={!isMobile ? {
															...Typography.bold20,
															color: Colors.primary
														} : {color: Colors.primary, fontSize: 12, fontWeight: 600}}

													>
														R$ {UtilsService.currencyFormat(parseInt(disciplina?.valor))} à
														vista
													</Text>
												</div>
												<div>
													<Text style={{...Typography.regular12, color: Colors.primary}}>
														ou {disciplina?.parcelas}x R${' '}
														{UtilsService.currencyFormat(parseInt(disciplina?.valor_parcelas))}
													</Text>
												</div>
											</div>
										</Col>
										<Col sm={4} xs={4} md={4} lg={24}>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
													justifyContent: 'center',
												}}>
												{/*{user?.id_tipo_pessoa != ALUNO && (*/}
												{!!props?.faculdade && faculdade?.id == props?.faculdade && (
													<Icon
														name={!!props?.matricula ? 'fluent-mdl2:open-enrollment' : 'akar-icons:edit'}
														color={Colors.orange}
														size={32}
													/>
												)}
												{!props?.aluno && !props?.faculdade && (
													<Icon
														name={!!props?.matricula ? 'fluent-mdl2:open-enrollment' : 'akar-icons:edit'}
														color={Colors.orange}
														size={32}
													/>
												)}
												{!!props?.aluno && (
													<Icon
														name={!!props?.matricula ? 'fluent-mdl2:open-enrollment' : 'akar-icons:edit'}
														color={Colors.orange}
														size={32}
													/>
												)}
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
							<div>
								<div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
									<Text

										style={!isMobile ? {
											...Typography.regular25,
											color: Colors.primary
										} : {color: Colors.primary, fontSize: 15, fontWeight: 600}}
									>
										{disciplina?.modalidade?.descricao}
									</Text>
									{!!disciplina?.horario?.descricao && (
										<>
											<Dot/>
											<Text style={{...Typography.regular25, color: Colors.primary}}>
												{disciplina?.horario?.descricao}
											</Text>
										</>
									)}
									{!!disciplina?.dia_semana?.descricao && (
										<>
											<Dot/>
											<Text style={{...Typography.regular25, color: Colors.primary}}>
												{disciplina?.dia_semana?.descricao}
											</Text>
										</>
									)}
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</a>
	);
};
