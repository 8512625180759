import React from 'react';
import IntlCurrencyInput from 'react-intl-currency-input';
import {Text} from '_atoms';
import {Colors, Typography} from '_styles';
import './styles.css';
const style = {...Typography.regular20};
export default props => {
	const {value, onChange, disabled, showPrefix} = props;

	const currencyConfig = {
		locale: 'pt-BR',
		formats: {
			number: {
				BRL: {
					style: showPrefix ? 'currency' : undefined,
					currency: 'BRL',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				},
			},
		},
	};

	return (
		<div style={{width: '100%'}}>
			{props.label && (
				<Text style={{...Typography.regular22, color: Colors.orange, paddingLeft: 18}}>
					{props.label}
					{props.required ? '*' : ''}
				</Text>
			)}
			<div style={{display: 'flex'}} className={'custom-input-wrapper'}>
				<div className={'custom-input-wrapper'} style={{paddingLeft: 20, paddingRight: 20}}>
					<Text style={{...Typography.regular22, color: Colors.orange}}>R$</Text>
				</div>
				<IntlCurrencyInput
					disabled={disabled}
					currency="BRL"
					config={currencyConfig}
					value={value}
					onChange={(event, value) => {
						if (typeof onChange === 'function') {
							onChange(value);
						}
					}}
					class={'ant-input wrapper'}
					style={{
						...style,
						...props.style,
					}}
				/>
			</div>
		</div>
	);
};
