import {Button, PageContent, Text} from '_atoms';
import {Row} from 'antd';
import {Colors, Typography} from '_styles';
import {Icon} from '_atoms';
import {redirect, useNavigate} from 'react-router-dom';
import React from 'react';
import {whats} from '_styles/colors';

export default () => {
	return (
		<div
			style={{
				position: 'fixed',
				bottom: 33,
				right: 33,
				flexDirection: 'column',
				display: 'flex',
				alignItems: 'center',
			}}>
			{/*<Text style={{...Typography.extraBold30, color: Colors.orange, textAlign: 'center'}}>Dúvidas</Text>*/}
			<Button
				style={{width: 50, height: 50}}
				type={'custom'}
				onClick={() => {
					window.open('https://wa.me/554988419594');
				}}>
				<Icon name={'akar-icons:whatsapp-fill'} color={Colors.whats} size={50} />
			</Button>
		</div>
	);
};
