import {Pagination} from 'antd';
import PrevIcon from './prevIcon.svg';
import NextIcon from './nextIcon.svg';

export default props => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'baseline',
				justifyContent: props?.align || 'center',
			}}>
			<Pagination
				{...props}
				nextIcon={<img src={NextIcon} />}
				prevIcon={<img src={PrevIcon} />}
				defaultCurrent={1}
				showSizeChanger={false}
				style={{
					display: 'flex',
					height: 60,
					justifyContent: 'center',
					alignContent: 'center',
					flexDirection: 'row',
				}}
			/>
		</div>
	);
};
