import { Row, Separator, Text,} from '_atoms';
import {RatingStar} from '_molecules';
import {Colors, Typography} from '_styles';
import {formataDinheiro} from '_services/utils-service';
import Logo from './logo.svg';
import {Col, Divider, Grid} from "antd";
import utils from "../../../utils";

export default ({disciplina, logo = null, faculdade, modalidade,}) => {
	const lista = [
		[
			{
				title: 'Instituição',
				value: faculdade?.nome_fantasia || faculdade?.razao_social,
			},
			{
				title: 'Carga horária total',
				value: disciplina?.carga_horaria,
			},
		],
		[
			{
				title: 'Área',
				value: disciplina?.area,
			},
			{
				title: 'Cidade',
				value: `${disciplina?.pessoa_juridica?.pessoa?.endereco?.cidade} - ${disciplina?.pessoa_juridica?.pessoa?.endereco?.estado}`,
			},
		],
		[
			{
				title: 'Pré-requisitos',
				value: disciplina?.pre_requisito,
			},
			{
				title: 'Professor',
				value: disciplina?.professor,
			},
		],
		[
			{
				title: 'Modalidade',
				value: modalidade?.descricao,
			},
			{
				title: 'Valor',
				value: `${formataDinheiro(parseInt(disciplina?.valor))} à vista ou ${
					disciplina?.parcelas
				}x ${formataDinheiro(parseInt(disciplina?.valor_parcelas))}`,
			},
		],
		[
			{
				title: 'Dia da semana',
				value: disciplina.id_dia_semana || disciplina.dia_semana,
				dia_semana: true,
			},
			{
				title: 'Horário',
				value: disciplina.id_horario || disciplina.horario,
				horario: true,
			},
		],
	];

	const RenderTitle = title => {
		return <Text style={!isMobile2 ? {...Typography.extraBold30, color: Colors.primary} : {
			color: Colors.primary,
			fontWeight: 800,
			fontsize: 20
		}}>{title?.texto}:</Text>;
	};

	const RenderLabel = value => {
		let texto = '';
		if (!!value?.dia_semana) {
			switch (value.texto) {
				case 1:
					texto = 'Domingo';
					break;
				case 2:
					texto = 'Segunda-feira';
					break;
				case 3:
					texto = 'Terça-feira';
					break;
				case 4:
					texto = 'Quarta-feira';
					break;
				case 5:
					texto = 'Quinta-feira';
					break;
				case 6:
					texto = 'Sexta-feira';
					break;
				case 7:
					texto = 'Sábado';
					break;
				default:
					texto = '';
					break;
			}
		}
		if (!!value?.horario) {
			switch (value.texto) {
				case 1:
					texto = 'Matutino';
					break;
				case 2:
					texto = 'Vespertino';
					break;
				case 3:
					texto = 'Noturno';
					break;
				default:
					texto = '';
					break;
			}
		}
		if (!value?.dia_semana && !value?.horario) {
			texto = value.texto;
		}
		return <Text
			style={!isMobile2 ? {...Typography.regular26, color: Colors.primary, paddingLeft: 5} : {
				color: Colors.primary,
				fontWeight: 500,
				fontsize: 18,
				paddingLeft: 5
			}}
		>{texto}</Text>;
	};

	const screens = utils.getBreakPoint(Grid.useBreakpoint());
	const isMobile = !screens.includes("lg");
	const isMobile2 = !screens.includes("md");

	const RenderRow = row => {
		return (
			<>
				<Row gutter={[0, 0]}>
					{row.line.map((v, index) => {

						return (
							<Col lg={12} md={24} sm={24} xs={24}
							     style={{
								     justifyContent: (isMobile ? "center" : (index % 2 !== 0 ? "right" : "left")),
								     display: 'flex',
								     alignItems: 'center',
								     marginTop: 20
							     }}>
								<RenderTitle texto={v.title}/>
								<RenderLabel texto={v.value} dia_semana={v?.dia_semana} horario={v?.horario}/>
							</Col>
						)
					})}
				</Row>
			</>
		);
	};

	return (
		<>
			<Row style={{justifyContent: 'center', width: "100%"}}>
				<div style={{backgroundColor: "#f8f5f5", padding: 15, borderRadius: 10, margin: 10, maxWidth: 500}}
				     className={"code-box"}>
					<img
						src={!!logo ? `data:image/jpeg;base64,${logo}` : Logo}
						style={{
							maxWidth: "100%",
							maxHeight: "100%",
							alignSelf: 'center',
							objectFit: "scale-down",
						}}
					/>
				</div>
			</Row>
			<Separator size={33}/>
			<div style={{justifyContent: 'center', alignItems: 'center'}}>
				<div>
					<RatingStar
						disabled={true}
						rating={parseInt(disciplina?.nota)}
						quantidadeAvaliacoes={disciplina?.quantidade_avaliacao}
					/>
				</div>
				<Row
					justify={'center'}
					style={{
						width: '100%',
						alignItems: 'center',
					}}
					gutter={20}
				>
					<Col>
						<Text style={{...Typography.extraBold30, color: Colors.primary}}>{disciplina?.nome}</Text>
					</Col>
					<Col>
						<Text style={{...Typography.regular26, color: Colors.primary}}>{faculdade?.nome_fantasia}</Text>
					</Col>
				</Row>

			</div>
			<Divider/>
			{lista.map(v => (
				<RenderRow line={v}/>
			))}
			<Divider/>
			<Row
				justify={'center'}
				style={{
					width: '100%',
					alignItems: 'center',
				}}
				gutter={20}>
				<Col>
					<RenderTitle texto={'Ementa'}/>
				</Col>
				<Col>
					<RenderLabel texto={disciplina?.ementa}/>
				</Col>
			</Row>
			<Divider/>
			<Separator size={20}/>
		</>
	);
};
