import {Form} from '_organisms';
import {useState} from 'react';
import {TextInput} from '_atoms';
import ReactCreditCards from 'react-credit-cards-2';
import 'react-credit-cards-2/es/styles-compiled.css';
import {Col, Row} from "antd";

export default ({dadosCartao, setDadosCartao, formCartao, ...props}) => {
	const [waiting, setWaiting] = useState(false);

	const fields = [
		{type: TextInput, name: 'numero', label: 'Número', rules: {required: true}, mask: '9999 9999 9999 9999'},
		{type: TextInput, name: 'nome', label: 'Nome', rules: {required: true}},
		{type: TextInput, name: 'validade', label: 'Data de validade', rules: {required: true}, mask: '99/9999'},
		{type: TextInput, name: 'codigo', label: 'CVC', rules: {required: true}, mask: '999'},
	];

	return (
		<Row gutter={[30, 20]}>
			<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
				<ReactCreditCards
					number={dadosCartao?.numero || ''}
					name={dadosCartao?.nome || ''}
					expiry={dadosCartao?.validade || ''}
					cvc={dadosCartao?.codigo || ''}
					focused={dadosCartao?.focused || ''}
				/>
			</Col>
			<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
				<Form
					form={formCartao}
					confirmLabel={'Confirmar'}
					fields={fields}
					values={dadosCartao}
					onValueChange={setDadosCartao}
					confirmOption={false}
					waiting={waiting}
				/>
			</Col>
		</Row>
	);
};
