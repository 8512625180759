import {Button, Loading, TextInput} from '_atoms';
import {useState} from 'react';

export default ({waiting = false, onSearch, placeholder, ...props}) => {
	return (
		<div style={{position: 'relative', width: '100%', flexDirection: 'row'}}>
			<TextInput {...props} style={{height: 50}} placeholder={placeholder} />
			{!waiting && (
				<Button
					style={{
						position: 'absolute',
						right: 0,
						top: 0,
						height: 50,
						zIndex: 99,
						display: 'flex',
						textAlign: 'center',
					}}
					onClick={onSearch}>
					Pesquisar
				</Button>
			)}
			{!!waiting && <Loading style={{position: 'absolute', right: 40, top: 7, zIndex: 99}} />}
		</div>
	);
};
