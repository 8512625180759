import '_styles/styles.css';
import {Button, Text} from '_atoms';
import {Colors, Typography} from '_styles';
import Separator from '_atoms/separator';
import {Modal, Radio} from '_molecules';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

export default ({open, setOpen, setOpenCadastro, ...props}) => {
	const navigate = useNavigate();

	const [redireciona, setRedireciona] = useState(0);

	const redirecionaTela = () => {
		setOpen(false);
		if (redireciona == 1) {
			// setOpen(false);
			setOpenCadastro(true);
			// navigate('/cadastro-aluno');
		}
		if (redireciona == 2) {
			navigate('/cadastro-instituicao');
		}
	};

	return (
		<Modal
			open={open}
			title={
				<div style={{textAlign: 'center', color: Colors.primary, ...Typography.bold40, paddingTop: 36}}>
					Seja bem-vindo(a)!
				</div>
			}
			centered={true}
			width={775}
			closable={true}
			// onOk={() => setOpen(false)}
			onCancel={() => setOpen(false)}
			footer={[
				<Button
					style={{marginLeft: 'auto', justifyContent: 'center', display: 'flex', alignItems: 'center'}}
					type={'primary'}
					onClick={() => redirecionaTela()}>
					Continuar
				</Button>,
			]}
			body={
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<Text style={{...Typography.semiBold20, color: Colors.orange}}>
						Antes de se cadastrar, nos informe, você é:
					</Text>
					<Separator size={30} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<Radio
							options={[
								{value: 1, label: 'Estudante'},
								{value: 2, label: 'Instituição de Ensino'},
							]}
							vertical={true}
							onChange={value => setRedireciona(value.target.value)}
						/>
					</div>
				</div>
			}
		/>
	);
};
