import {Row, Separator} from '_atoms';
import {SearchInput} from '_molecules';
import Simbolo2 from '_assets/images/login/simbolo2.svg';
import utils from "../../../utils";
import {Grid} from "antd";

export default ({waiting = false, onSearch, onChange,}) => {

	const screens = utils.getBreakPoint(Grid.useBreakpoint());
	const isMobile = !screens.includes("md");
	
	return (
		<>
			<Row style={{display: 'flex', justifyContent: 'center'}}>
				<div
					style={{
						width: '80%',
						display: 'flex',
						alignSelf: 'center',
						position: 'relative',
					}}>
					<SearchInput
						placeholder={'Busque o nome da disciplina desejada'}
						onSearch={onSearch}
						onChange={value => onChange(value.target.value)}
						waiting={waiting}
					/>

					{!isMobile && <img src={Simbolo2} style={{position: 'absolute', top: -120, right: -60}} />}
					
					
				</div>
			</Row>
			<Separator size={70} />
		</>
	);
};
