import React, {useState} from 'react';
import Star from './star.svg';
import StarFilled from './starFilled.svg';
import MiddleStar from './middleStar.svg';
import {Button, Text} from '_atoms';
import {Colors, Typography} from '_styles';
import utils from "../../../utils";
import {Grid} from "antd";

const RatingStar = ({rating = 0, quantidadeAvaliacoes, onChange, ...props}) => {
	const [maxRating, setMaxRating] = useState(props?.maxRating || [1, 2, 3, 4, 5]);

	const screens = utils.getBreakPoint(Grid.useBreakpoint());
	const isMobile = !screens.includes("lg");
	
	return (
		<div
			style={{
				display: 'flex',
				// flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			{maxRating.map((item,) => {
				return (
					<Button
						key={item}
						onClick={() => onChange(item)}
						type={'custom'}
						disabled={props?.disabled}
						style={{padding: 0}}>
						<img
							style={{width: (isMobile ? 15 : 20), height: 19, marginInline: 1.5}}
							src={item <= rating ? Star : item <= rating + 0.5 ? MiddleStar : StarFilled}
						 alt={"no img"}/>
					</Button>
				);
			})}
			{quantidadeAvaliacoes && (
				<Text style={{...Typography.regular16, color: Colors.orange, paddingLeft: 5, paddingTop: 2.5}}>
					{quantidadeAvaliacoes ? ` ${quantidadeAvaliacoes} ` : '0'}
				</Text>
			)}
		</div>
	);
};

export default RatingStar;
