import 'iconify-icon';

export default ({iconFamily = 'iconify', size, color, name, ...props}) => {
	return (
		<>
			{iconFamily === 'iconify' && (
				<iconify-icon icon={name} style={{color, ...props.style}} width={size} height={size} {...props} />
			)}
		</>
	);
};
