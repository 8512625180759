import {Radio, Space} from 'antd';
import {Colors, Typography} from '_styles';
import {Text} from '_atoms';
import '_styles/styles.css';
import {RadioOption} from '_molecules';
export default ({options, ...props}) => {
	return (
		<div style={{width: '100%'}}>
			{props.label && (
				<Text style={{...Typography.regular22, color: Colors.orange, paddingLeft: 18}}>
					{props.label}
					{props.required ? '*' : ''}
				</Text>
			)}
			<div
				style={{
					...props.style,
				}}>
				<Radio.Group {...props} style={{...Typography.regular20, color: Colors.black}}>
					{!!props?.vertical && (
						<Space direction="vertical">
							{options?.map((v, k) => (
								<RadioOption
									key={k}
									value={v.value}
									style={!!v?.style ? v.style : {...Typography.regular20, color: Colors.orange}}>
									{v.label ?? v.value}
								</RadioOption>
							))}
						</Space>
					)}
					{!props?.vertical && (
						<div>
							{options?.map((v, k) => (
								<RadioOption
									key={k}
									value={v.value}
									style={!!v?.style ? v.style : {...Typography.regular20, color: Colors.orange}}>
									{v.label ?? v.value}
								</RadioOption>
							))}
						</div>
					)}
				</Radio.Group>
			</div>
		</div>
	);
};
