import {Input} from 'antd';
import {Colors, Typography} from '_styles';
import {Text} from '_atoms';
import '_styles/styles.css';
import './styles.css';

const style = {...Typography.regular20};
export default ({rows = 6, ...props}) => {
	return (
		<div style={{width: '100%'}}>
			{props.label && (
				<Text style={{...Typography.regular22, color: Colors.orange, paddingLeft: 18}}>
					{props.label}
					{props.required ? '*' : ''}
				</Text>
			)}
			<Input.TextArea
				{...props}
				rows={rows}
				className={'custom-input-wrapper'}
				style={{
					...style,
					...props.style,
				}}
			/>
		</div>
	);
};
