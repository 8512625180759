import {message, Upload} from 'antd';
import {Icon, Text} from '_atoms';
import {Colors, Typography} from '_styles';
import '_styles/styles.css';
import './styles.css';

const {Dragger} = Upload;

export default props => {
	return (
		<>
			{!!props?.dragger ? (
				<Dragger {...props}>
					<p className="ant-upload-drag-icon"></p>
					<p className="ant-upload-text">Clique ou arraste para inserir o arquivo</p>
					<p className="ant-upload-hint"></p>
				</Dragger>
			) : (
				<Upload {...props}>
					<div style={{width: '100%'}}>
						{props.label && (
							<Text style={{...Typography.regular22, color: Colors.orange, paddingLeft: 18}}>
								{props.label}
								{props.required ? '*' : ''}
							</Text>
						)}
						<div
							{...props}
							className={'custom-input-wrapper upload'}
							style={{
								...Typography.regular20,
								color: Colors.grey,
								display: 'flex',
								alignItems: 'center',
								paddingLeft: 18,
							}}>
							{props.placeholder}
							{/*{props.fileList.length >= 1 ? null : uploadButton}*/}
							<Icon
								name={'bxs:cloud-upload'}
								color={Colors.grey}
								size={35}
								style={{marginLeft: 76, marginRight: 35}}
							/>
						</div>
					</div>

					{/*{error && <Text style={{...Typography.regular16, color: Colors.error, paddingLeft: 16}}>{error}</Text>}*/}
				</Upload>
			)}
		</>
	);
};
