import {DatePicker, TimePicker} from 'antd';
import {Text} from '_atoms';
import {Colors, Typography} from '_styles';
import './styles.css';
import dayjs from 'dayjs';
const style = {...Typography.regular20};

export default ({
	disabledDate = false,
	format = 'DD/MM/YYYY',
	placeholder,
	range = false,
	returnString = false,
	...props
}) => {
	placeholder = placeholder ? placeholder : range ? ['Início', 'Fim'] : 'Selecione a data';

	return (
		<div style={{width: '100%'}}>
			{props.label && (
				<Text style={{...Typography.regular22, color: Colors.orange, paddingLeft: 18}}>
					{props.label}
					{props.required ? '*' : ''}
				</Text>
			)}
			<div
				style={{
					...props.style,
				}}
				className={'custom-input-wrapper'}>
				{!range && (
					<DatePicker
						{...props}
						style={{...style, width: '100%', ...props.style}}
						format={format}
						placeholder={placeholder}
						bordered={false}
						showNow={false}
						disabledDate={disabledDate}
						onChange={(dayjs, string) => {
							if (returnString) {
								props.setValue(string);
							} else {
								props.setValue(dayjs);
							}
						}}
						// inputReadOnly={true}
					/>
				)}
				{range && (
					<DatePicker.RangePicker
						{...props}
						style={{...style, width: '100%', ...props.style}}
						format={format}
						placeholder={placeholder}
						bordered={false}
						showNow={false}
						onChange={(dayjs, string) => {
							if (returnString) {
								props.setValue(string);
							} else {
								props.setValue(dayjs);
							}
						}}
					/>
				)}
			</div>
		</div>
	);
};
