import '_styles/styles.css';
import {Button, FormSubTitle, Loading, Password, Text, TextInput} from '_atoms';
import {Colors, Typography} from '_styles';
import Separator from '_atoms/separator';
import {DatePicker, Modal, Radio, Select} from '_molecules';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Form} from '_organisms';
import Google from './google.png';
import Facebook from './facebook.png';
import AlunoService from '_services/alunoService';
import {useGoogleLogin} from '@react-oauth/google';
import {GoogleOAuthProvider} from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {api, CidadesService, EstadosService} from '_services';
import dayjs from 'dayjs';
import CadastroAluno from '_pages/app/cadastro-aluno';
import axios from 'axios';

export default ({open = false, setOpen, ...props}) => {
	const navigate = useNavigate();
	const [userGoogle, setUserGoogle] = useState([]);
	const [google, setGoogle] = useState([]);
	const [userFacebook, setUserFacebook] = useState([]);
	const [facebook, setFacebook] = useState([]);

	const GOOGLE_ID = '993582365956-melgshrjr5mk9cj44csbgpda94o0ke2v.apps.googleusercontent.com';

	const responseGoogle = response => {
		setUserGoogle(response);
		
	};

	useEffect(() => {
		if (userGoogle) {
			axios
				.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userGoogle.access_token}`, {
					headers: {
						Authorization: `Bearer ${userGoogle.access_token}`,
						Accept: 'application/json',
					},
				})
				.then(res => {
					setGoogle(res.data);
				})
				.catch(err => console.log(err));
		}
	}, [userGoogle]);

	const responseFacebook = response => {
		setUserFacebook(response);
		console.log(response);
	};

	const GoogleButton = () => {
		const login = useGoogleLogin({
			onSuccess: responseGoogle,
		});

		return (
			<Button
				style={{
					width: 77,
					height: 77,
					borderRadius: 50,
					marginInline: 24,
					backgroundColor: 'transparent',
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
				}}
				onClick={login}>
				<img src={Google} style={{width: 69, height: 69, alignSelf: 'center'}} />
			</Button>
		);
	};

	const FacebookButton = ({onClick}) => {
		return (
			<Button
				style={{
					width: 77,
					height: 77,
					borderRadius: 50,
					marginInline: 24,
					backgroundColor: 'transparent',
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
				}}
				onClick={onClick}>
				<img src={Facebook} style={{width: 69, height: 69, alignSelf: 'center'}} />
			</Button>
		);
	};

	// const login = useGoogleLogin({
	// 	onSuccess: codeResponse => console.log(codeResponse),
	// 	flow: 'auth-code',
	// });

	return (
		<Modal
			open={open}
			title={
				<div style={{textAlign: 'center', color: Colors.primary, ...Typography.bold20, paddingTop: 36}}>
					Para ver mais, cadastre-se rapidamente :)
				</div>
			}
			width={'auto'}
			closable={false}
			onOk={() => setOpen(false)}
			onCancel={() => setOpen(false)}
			footer={[<></>]}
			body={
				<div>
					<div
						style={{
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<CadastroAluno setOpen={() => setOpen(false)} google={google} />
						{/* <Text style={{...Typography.regular20, color: Colors.primary}}>ou</Text>
						<Text style={{...Typography.regular20, color: Colors.orange}}>entre com algum desses</Text>
						<Separator size={30} />
						<div
							style={{
								flexDirection: 'row',
								alignContent: 'space-between',
								alignItems: 'center',
								display: 'flex',
							}}>
							<GoogleOAuthProvider
								// children={<GoogleButton />}
								clientId={GOOGLE_ID}>
								<GoogleButton />
							</GoogleOAuthProvider>
							<FacebookLogin
								appId="729991202070474"
								autoLoad={false}
								fields="name,email,picture"
								callback={responseFacebook}
								render={renderProps => <FacebookButton onClick={renderProps.onClick} />}
							/>
						</div> */}
						<Separator size={40} />
					</div>
				</div>
			}
		/>
	);
};
