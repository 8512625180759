import DesenhoLogo from '_assets/images/desenho_logo.svg';
import Logo from '_assets/images/logo.svg';
import {Col, Dropdown, Grid, Menu, Row} from 'antd';
import {Colors} from '_styles';
import {PageContent, Button} from '_atoms';
import {AUTH_TOKEN} from '../../../redux/constants/Auth';
import {useNavigate} from 'react-router-dom';
import {getUsuario} from '_services/usuarioService';
import React, {useState} from 'react';
import {ModalCadastro, ModalLogin} from '_organisms';
import {ALUNO} from '_services/tipoPessoaService';
import utils from '../../../utils';
import Menusvg from '_assets/images/menusvg.svg';

const {useBreakpoint} = Grid;

export default ({
	showRightButton = true,
	showLeftButton = true,
	leftButtonLabel = 'Entrar',
	rightButtonLabel = 'Cadastre-se',
	leftButton,
	rightButton,
}) => {
	const navigate = useNavigate();

	const user = getUsuario();
	const [open, setOpen] = useState(false);
	const [openCadastro, setOpenCadastro] = useState(false);
	const [buttonsVisible, setButtonsVisible] = useState(true);

	const logout = () => {
		localStorage.clear();
		navigate('/login');
	};

	const screens = utils.getBreakPoint(useBreakpoint());
	const isMobile = !screens.includes('lg');

	const handleMenuClick = ({key}) => {
		switch (key) {
			case 'perfil':
				if (user?.id_tipo_pessoa === ALUNO) {
					navigate('/perfil-aluno');
				} else {
					navigate('/home-instituicao');
				}
				break;
			case 'logout':
				{
					localStorage.getItem(AUTH_TOKEN) ? logout() : setOpen(true);
				}
				break;
			default:
				break;
		}
	};

	const renderDropdownMenu = () => {
		return (
			<Menu onClick={handleMenuClick}>
				<Menu.Item key="perfil">
					{localStorage.getItem(AUTH_TOKEN) ? `Olá, ${user.nome}` : leftButtonLabel}
				</Menu.Item>
				<Menu.Item key="logout">{localStorage.getItem(AUTH_TOKEN) ? 'Sair' : rightButtonLabel}</Menu.Item>
			</Menu>
		);
	};

	return (
		<PageContent>
			{!isMobile && (
				<Row
					gutter={16}
					style={{
						display: 'flex',
						justifyContent: buttonsVisible ? 'space-between' : 'end',

						paddingTop: 20,
						paddingBottom: 60,
						backgroundColor: Colors.white,
					}}>
					{!isMobile && buttonsVisible && !localStorage.getItem(AUTH_TOKEN) && (
						<Col xl={12} style={{display: 'flex', justifyContent: 'start'}} className="col-left-button">
							{showLeftButton &&
								(leftButton ? (
									leftButton
								) : (
									<Button
										style={{width: 'auto', paddingRight: 20, paddingLeft: 20, marginRight: 20}}
										// onClick={onPressLeftButton}
										onClick={() => {
											navigate('/landing-page-estudante');
											setButtonsVisible(false);
										}}
										type={'primary'}>
										Sou Estudante
									</Button>
								))}
							{showRightButton &&
								(rightButton ? (
									rightButton
								) : (
									<Button
										style={{
											width: 'auto',
											paddingInline: 10,
											paddingRight: 20,
											paddingLeft: 20,
										}}
										onClick={() => {
											navigate('/landing-page-instituicao');
											setButtonsVisible(false);
										}}
										type={'primary'}>
										Sou IES
									</Button>
								))}
						</Col>
					)}
					{!isMobile && (
						<Col
							xl={buttonsVisible ? 12 : 24}
							style={{display: 'flex', justifyContent: 'end', marginLeft: 'auto'}}>
							{showLeftButton &&
								(leftButton ? (
									leftButton
								) : (
									<Button
										style={{width: 'auto', paddingRight: 20, paddingLeft: 20, marginRight: 20}}
										// onClick={onPressLeftButton}
										onClick={
											user?.id_tipo_pessoa == ALUNO
												? () => navigate('/perfil-aluno')
												: () => navigate('/home-instituicao')
										}
										type={localStorage.getItem(AUTH_TOKEN) ? 'primary' : 'secondary'}>
										{localStorage.getItem(AUTH_TOKEN) ? `Olá, ${user.nome}` : leftButtonLabel}
									</Button>
								))}
							{showRightButton &&
								(rightButton ? (
									rightButton
								) : (
									<Button
										style={{
											width: 'auto',
											paddingInline: 10,
											paddingRight: 20,
											paddingLeft: 20,
										}}
										onClick={
											localStorage.getItem(AUTH_TOKEN) ? () => logout() : () => setOpen(true)
										}
										type={localStorage.getItem(AUTH_TOKEN) ? 'secondary' : 'primary'}>
										{localStorage.getItem(AUTH_TOKEN) ? 'Sair' : rightButtonLabel}
									</Button>
								))}
						</Col>
					)}
				</Row>
			)}

			<Row gutter={16}>
				<Col
					xl={4}
					xs={3}
					style={{
						display: 'flex',
						justifyContent: 'start',
					}}>
					<div
						style={{
							padding: 10,
							display: 'flex',
							alignItems: 'center',
							maxWidth: 124,
						}}>
						<img src={DesenhoLogo} width={'100%'} height={'100%'} style={{objectFit: 'scale-down'}} />
					</div>
				</Col>
				<Col
					xl={16}
					xs={18}
					style={{
						padding: 10,

						justifyContent: 'start',
					}}>
					<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<Button
							type={'custom'}
							onClick={() => navigate('/')}
							style={{maxWidth: 391}}
							className="button ">
							<img
								src={Logo}
								alt={'not img'}
								width={'100%'}
								height={'100%'}
								style={{objectFit: 'scale-down'}}
							/>
						</Button>
					</div>
				</Col>
				{isMobile && (showRightButton || showLeftButton) && (
					<Col
						xl={4}
						xs={3}
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
						}}>
						<div style={{width: '50px'}}>
							<Dropdown overlay={renderDropdownMenu} placement="bottomRight">
								<img src={Menusvg} alt="Menu" />
							</Dropdown>
						</div>
					</Col>
				)}
			</Row>

			<ModalLogin open={open} setOpen={setOpen} setOpenCadastro={setOpenCadastro} />
			<ModalCadastro open={openCadastro} setOpen={setOpenCadastro} />
		</PageContent>
	);
};
