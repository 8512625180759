import {List, Empty, Button, Form as FormAntd, Switch} from 'antd';
import {Form} from '_organisms';
import {Row, Text, TextInput} from '_atoms';
import {useEffect, useState} from 'react';
import {getUsuario} from '_services/usuarioService';
import AlunoService from '_services/alunoService';
import moment from 'moment/moment';
import {currencyFormat} from '_services/utils-service';
import {Colors, Typography} from '_styles';

export default props => {
	const [waiting, setWaiting] = useState(false);
	const [values, setValues] = useState({});
	const [newValues, setNewValues] = useState({});
	const user = getUsuario();
	const [form] = FormAntd.useForm();

	const updateValues = (response, valor) => {
		setNewValues({
			email: response?.pessoa?.usuario?.email,
			nome: response?.pessoa?.nome,
			telefone: response?.pessoa?.telefone,
		});
	};

	useEffect(() => {
		AlunoService.get(user?.pessoa_fisica?.id).then(aluno => {
			updateValues(aluno.data);
		});
	}, []);

	const onSwitch = checked => {
		if (!!checked) {
			setValues(newValues);
		} else {
			setValues({});
			form.resetFields();
		}
	};

	return (
		<List
			{...props}
			locale={{
				emptyText: (
					<Empty
						imageStyle={{height: 80}}
						description={
							<Text
								style={
									!!props?.contato
										? {
												...Typography.semiBold20,
												color: Colors.orange,
										  }
										: {}
								}>
								{!!props?.contato
									? // ? 'Não encontramos essa disciplina - deixe seu contato que vamos providenciar'
									  'Não encontramos essa disciplina'
									: 'Nenhum item encontrado.'}
							</Text>
						}>
						{/*{!!props?.contato && (*/}
						{/*	<>*/}
						{/*		<Row*/}
						{/*			style={{*/}
						{/*				display: 'flex',*/}
						{/*				justifyContent: 'right',*/}
						{/*				flexDirection: 'row',*/}
						{/*				alignContent: 'center',*/}
						{/*				alignItems: 'center',*/}
						{/*			}}>*/}
						{/*			<Switch onChange={onSwitch} />*/}
						{/*			<Text style={{...Typography.regular20, color: Colors.primary, paddingLeft: 10}}>*/}
						{/*				{'Usar informações do meu cadastro'}*/}
						{/*			</Text>*/}
						{/*		</Row>*/}
						{/*		<Form*/}
						{/*			form={form}*/}
						{/*			waiting={waiting}*/}
						{/*			values={values}*/}
						{/*			onValueChange={setValues}*/}
						{/*			fields={[*/}
						{/*				{*/}
						{/*					type: TextInput,*/}
						{/*					name: 'nome',*/}
						{/*					label: 'Nome',*/}
						{/*					rules: {*/}
						{/*						required: true,*/}
						{/*						message: 'Por favor insira seu nome!',*/}
						{/*					},*/}
						{/*				},*/}
						{/*				[*/}
						{/*					{*/}
						{/*						type: TextInput,*/}
						{/*						name: 'email',*/}
						{/*						label: 'Email',*/}
						{/*						rules: {*/}
						{/*							required: true,*/}
						{/*							message: 'Por favor insira seu e-mail!',*/}
						{/*							type: 'email',*/}
						{/*						},*/}
						{/*					},*/}
						{/*					{*/}
						{/*						type: TextInput,*/}
						{/*						name: 'telefone',*/}
						{/*						mask:*/}
						{/*							values.telefone?.replace(/\D/g, '').length > 12*/}
						{/*								? '+5\\5 (99) 99999-9999'*/}
						{/*								: '+5\\5 (99) 9999-99999',*/}
						{/*						maskChar: '',*/}
						{/*						label: 'Telefone',*/}
						{/*						rules: {required: true},*/}
						{/*					},*/}
						{/*				],*/}
						{/*			]}*/}
						{/*			onFinish={() => {*/}
						{/*				console.log('');*/}
						{/*			}}*/}
						{/*			confirmLabel={'Enviar'}*/}
						{/*			backOption={false}*/}
						{/*		/>*/}
						{/*	</>*/}
						{/*)}*/}
					</Empty>
				),
			}}
		/>
	);
};
